import "@fontsource/roboto"
import "./src/styles/foundation/variable.css"
import "./src/styles/foundation/normalize.css"
import "./src/styles/foundation/base.css"
import "./src/styles/foundation/blog-contents-style.css"

document.addEventListener('DOMContentLoaded', () => {
  const script = document.createElement('script');
  script.src = 'https://use.typekit.net/ghq4aov.js';
  script.async = true;
  script.onload = () => {
    if (window.Typekit) {
      window.Typekit.load();
    }
  };
  document.head.appendChild(script);
});